import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {Splash} from "./features/splash/components/Splash";
import {Theme} from "./features/theme/Theme";
import {store} from "./app/store";
import {saveState} from "./app/browser-storage";
import {useAppDispatch, useScreenOrientation} from "./app/hooks";
import {setMode} from "./features/theme/themeSlice";
import Modal from 'react-modal';
import {PinScreen} from "./features/vendor/components/PinScreen";
import {Load} from "./features/splash/components/Load";
import {MultiVendor} from "./features/vendor/components/MultiVendor";
import {CartScreenGuard} from "./features/cart/components/CartScreenGuard";
import {CheckoutScreenGuard} from "./features/cart/components/checkout/CheckoutScreenGuard";
import {LoginScreenGuard} from "./features/user/components/LoginScreenGuard";
import {RegisterScreenGuard} from "./features/user/components/RegisterScreenGuard";
import {OrderStatusScreenGuard} from "./features/cart/components/orderStatus/OrderStatusScreenGuard";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Translate} from "./features/intl/translator";
import {MenuMemo} from "./features/menu/components/MenuScreen/Menu";
import {JccGatewayRedirect} from "./features/cart/components/gateways/Jcc/JccGatewayRedirect";
import {OrderPaymentFailed} from "./features/cart/components/orderStatus/OrderPaymentFailed";
import {WaiterLoginScreen} from "./features/user/components/WaiterLoginScreen";

function App() {
    const strings = {
        s_no_internet_msg: Translate("no_internet_msg")
    }
    const dispatch = useAppDispatch();
    const orientation = useScreenOrientation();
    const [connectionStatus, setConnectionStatus] = useState(true);
    const connectionToastId = React.useRef("");
   
    Modal.setAppElement('#root');

    useEffect(() => {
        

        // if ("Notification" in window && Notification.permission !== "granted") {
        //     Notification.requestPermission().then((permission) => {
        //         if (permission === "granted") {
        //             console.log("Notification permission granted.");
        //         } else {
        //             console.warn("Notification permission denied.");
        //         }
        //     });
        // }
        window.addEventListener('offline', (event) => {
            setConnectionStatus(false);
        });
        window.addEventListener('online', (event) => {
            setConnectionStatus(true);
        });
        window.addEventListener('load', function() {
            window.history.pushState({}, '');
        })
    },[])

    useEffect(() => {
        if(!connectionStatus){
            connectionToastId.current = toast(strings.s_no_internet_msg, {
                containerId: 'basic',
                position: "bottom-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                type: "error",
            }) as string;
        }else{
            toast.dismiss(connectionToastId.current);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[connectionStatus])



    // set mode if user has browser in dark mode this will not override if user specified mode in nina settings
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        dispatch(setMode('dark'));
    }

    store.subscribe(
        () => {
            saveState(store.getState());
        }
    );


    if (orientation === 'landscape-primary') {
        // return <SplashLandscape />;
        // if(process.env.NODE_ENV !== "development"){
        //     return <LoggedOut />;
        // }else{
        //     return <SplashLandscape />;
        // }
    }

    return (
        <Theme>
            <Routes>
                <Route path="/:urlId" element={<Load />} />
                <Route path="/load" element={<Load />} />
                <Route path="/menu" element={<MenuMemo/>} />
                <Route path="/pin" element={<PinScreen />} />
                <Route path="/outlets" element={<MultiVendor />} />
                <Route path="/splash" element={<Splash />} />
                <Route path="/cart" element={<CartScreenGuard />} />
                <Route path="/login" element={<LoginScreenGuard />} />
                <Route path="/waiter/login" element={<WaiterLoginScreen />} />
                <Route path="/register" element={<RegisterScreenGuard />} />
                <Route path="/checkout" element={<CheckoutScreenGuard />} />
                <Route path="/order" element={<OrderStatusScreenGuard />} />
                <Route path="/order/failed" element={<OrderPaymentFailed />} />
                <Route path="/order/failed/:orderId" element={<OrderPaymentFailed />} />
                <Route path="/order/:orderId/:payId" element={<OrderStatusScreenGuard />} />
                <Route path="/jccPayment/:orderId/:accountId/:orderTotal" element={<JccGatewayRedirect />} />
                <Route path="/" element={<LoggedOut />} />
            </Routes>

        </Theme>
    );
}
export default App;


function LoggedOut() {
    if(process.env.NODE_ENV !== "development"){
        document.location.replace("https://ninatech.co")
    }
    return (
        <div></div>
    );
}
