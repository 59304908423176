import {OrderScreenHeader} from "../../../../styled_components/Cart";
import {AppCredits} from "../../../core/components/AppCredits";
import React, {useEffect, useState} from "react";
import {loadOrderPerPaymentRequestId} from "../../cartRepository";
import {AiOutlineClose, AiOutlineLoading3Quarters} from "react-icons/ai";
import {Translate} from "../../../intl/translator";
import {OrderStatusScreenItem} from "./OrderStatusScreenItem";
import {NinaOrder} from "../../NinaOrder";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {loadOrder} from "../../cartRepository";
import {TextP, Title1, Title2} from "../../../../styled_components/General";
import {v4 as uuid} from "uuid";
import {selectLocale} from "../../../intl/intlSlice";
import {addOrderToPastOrders, afterOrderSubmit, PastOrderI, selectLastOrder} from "../../ordersSlice";
import {clearCart} from "../../cartSlice";
import {setRescanStatus} from "../../../session/sessionSlice";
import {useParams} from "react-router-dom";
import {getOrderStatusColor, getOrderStatusLabel, getPaymentIconById, getPaymentTypeById} from "../../cartService";
import {getTableDetails} from "../../../vendor/vendorRepository";
import {selectTable,selectVendorPaymentMethods} from "../../../vendor/vendorSlice";
import {NotificationToast} from "../../../../styled_components/Notifications";
import {toast} from "react-toastify";
import {formatCurrency} from "../../../core/utils/format";
import { table } from "console";
import { getMessaging, onMessage } from 'firebase/messaging';
import firebase from 'firebase/compat/app';
import CONFIG from "../../../../app/config";
import { initializeApp } from "firebase/app";
export function OrderStatusScreen() {
    const strings = {
      s_order: Translate('order'),
      s_order_status_outlet_title: Translate('order_status_outlet_title'),
      s_order_status_date_title: Translate('order_status_date_title'),
      s_order_status_orderId_title: Translate('order_status_orderId_title'),
      s_order_status_table_title: Translate('order_status_table_title'),
      s_order_status_items_title: Translate('order_status_items_title'),
      s_order_status_comments_title: Translate('order_status_comments_title'),
      s_order_status_total_title: Translate('order_status_total_title'),
      s_order_status_payment_title: Translate('order_status_payment_title'),
      s_orderMsg: Translate('orderMsg'),
      s_orderDefualt: Translate('orderDefault'),
    };
    const firebaseApp = initializeApp(CONFIG.FIREBASE_CONFIG);
      
   
      const  messaging = getMessaging(firebaseApp);
        // console.log('Firebase Messaging loaded ')
 
    
    const [statusOrderMsg, setStatusOrderMsg] = useState(strings.s_orderDefualt);

    const initOrder = {
        Status: "new",
        OrderID: "0",
        OutletID: "0",
        AmountTotal: "0",
        Orderitems: []
    }
    const table = useAppSelector(selectTable);
    const locale = useAppSelector(selectLocale);
    const lastOrder = useAppSelector(selectLastOrder);
    const dispatch = useAppDispatch();
    const paymentMethods = useAppSelector(selectVendorPaymentMethods);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(new NinaOrder(initOrder));
    const [tableNo, setTableNo] = useState("-")
    const {orderId, payId} = useParams();
    const [orderMsg, setOrderMsg] = useState("");

    const loadLastOrder = () => {
        if (orderId && payId) {
            if (orderId == payId){
                console.log("other payment")
                loadOrderInfoNew(payId).then(res => {
                                })
            }else if (lastOrder && lastOrder.PaymentRequestID === payId) {
                console.log("JCC")
                setStatusOrderMsg("New");
                lastOrder.OrderId = orderId;
                let lastOrderToUpdate: PastOrderI = {...lastOrder};
                lastOrderToUpdate.OrderId = orderId;
                loadOrderInfo(orderId).then(res => {
                    setLoading(false)
                    dispatch(addOrderToPastOrders(lastOrderToUpdate));
                })
                
            }
        } else if (lastOrder && lastOrder.OrderId !== "0") {
            // setStatusOrderMsg("tests");
            // console.log("cash here "+lastOrder.OrderId)
            loadOrderInfo(lastOrder.OrderId).then(res => setLoading(false))
        }
    }
    const loadOrderInfoNew = (ptypeId: string) => {
        return new Promise((resolve, reject) => {
            // console.log("try to get data for "+ptypeId)
            loadOrderPerPaymentRequestId(ptypeId, locale.LanguageID).then(res => {
                setOrder(new NinaOrder(res));
                
                // console.log("here "+res.OrderID)
                if (!res.OrderID){
                    loadOrderInfoNew(ptypeId).then(res => {
                   
                        // dispatch(addOrderToPastOrders(lastOrderToUpdate));
                    })
                }else{
                    console.log("Order status: "+res.Status)
                    
                    setStatusOrderMsg(res.Status);
                    // if (res.Status == "Completed"){
                        setOrderMsg(res.Status);
                    // }else{
                        
                    // }
                    
                    if(res.OrderMessage && res.OrderMessage !== orderMsg){
                        setOrderMsg(res.OrderMessage);
                    }
                    if (tableNo === "-") {
                        loadTableInfo(res.TableID ?? "0")
                    }
                    setLoading(false)
                }
                
                resolve(res);
            }).catch(e => reject(e))
        })
    }

    // const loadLastOrder = () => {
    //     if(orderId && payId){
    //         console.log("lastOrder.PaymentRequestID: "+lastOrder?.PaymentRequestID);
    //         // console.log("lastOrder.PaymentRequestID: "+ lastOrder.PaymentRequestID);
    //         console.log("orderID/payId: "+ orderId+ "/" +payId);
    //         if(lastOrder && lastOrder.PaymentRequestID === payId){
    //             lastOrder.OrderId = orderId;
    //             let lastOrderToUpdate: PastOrderI = {...lastOrder};
    //             lastOrderToUpdate.OrderId = orderId;
    //             console.log("lastOrderToUpdate.OrderId: "+lastOrderToUpdate.OrderId);
    //             loadOrderInfo(orderId).then(res => {
    //                 setLoading(false)
    //                 dispatch(addOrderToPastOrders(lastOrderToUpdate));
    //                 dispatch(afterOrderSubmit(lastOrderToUpdate));
    //             })
    //         }
    //     }else if(lastOrder && lastOrder.OrderId !== "0"){
    //         loadOrderInfo(lastOrder.OrderId).then(res => setLoading(false))
    //     }
    // }

    const loadOrderInfo = (orderId: string) => {
        return new Promise((resolve, reject) => {
            loadOrder(orderId , locale.LanguageID).then(res => {
                setOrder(new NinaOrder(res));
                 setStatusOrderMsg(res.Status)
                if(res.OrderMessage && res.OrderMessage !== orderMsg){
                    setOrderMsg(res.Status);
                   
                    
                }
                if(tableNo === "-"){
                    loadTableInfo(res.TableID ?? "0")
                }
                resolve(res);
            }).catch(e => reject(e))
        })
    }

    const loadTableInfo = (tableId: string) => {
        getTableDetails(tableId).then(table => {
            setTableNo(table.TableNo)
        }).catch((e) => setTableNo("0"))
    }

    useEffect(() => {
        dispatch(clearCart());
        dispatch(setRescanStatus(true));
        loadLastOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        const refreshOrder = setInterval(() => {
            loadLastOrder();
        }, 4000)
        if(['Completed', "Canceled","Cancelled"].includes(order.info.Status)){
            clearInterval(refreshOrder);
        }
        return () => {
            clearInterval(refreshOrder);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[order])

    useEffect(() => {
        if(orderMsg.length > 0){
            toast(orderMsg, {
                containerId: 'order-status',
                position: "bottom-center",
                autoClose: false,
                hideProgressBar: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                type: "success",
            });
        }
    },[orderMsg])

    const CloseNotificationButton = ({closeToast}: any) => (
        <AiOutlineClose onClick={closeToast} color="white" />
    )
    useEffect(() => {
        // Listen for foreground messages
        onMessage(messaging, (payload) => {
    
          // Update the content on your page
          const title = payload.notification?.title;
          const body = payload.notification?.body;
        //   alert(`Notification received: ${title} - ${body}`); // Show as an alert
        setOrderMsg(body+"");
        toast(orderMsg, {
            containerId: 'order-status',
            position: "bottom-center",
            autoClose: false,
            hideProgressBar: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            type: "success",
        });
      
          // Alternatively, you can update a state variable to display it in the UI
        });
      }, [orderMsg]);
    
    //   return (
    //     <div>
    //       <h1>React Firebase Notification Example</h1>
    //       <p>Notifications will show up here or as an alert when received.</p>
    //     </div>
    //   );

    return (
        <div className="flex flex-col h-screen">
            <OrderScreenHeader color={getOrderStatusColor(order.info.Status)}>
                {!loading &&
                    <h2 className="text-center">{strings.s_order} <br/>
                        {getOrderStatusLabel(statusOrderMsg)}!
                    </h2>
                }

            </OrderScreenHeader>
            <div className="grow indent pt-2">
                {!loading &&
                    <div>
                        <div>
                            <p style={{color: "grey"}}>{strings.s_order_status_outlet_title}</p>
                            <p className="text-lg">{order.info.Name}</p>
                        </div>
                        <div className="flex place-content-between pt-8 pb-8">
                            <div>
                                <p style={{color: "grey"}}>{strings.s_order_status_date_title}</p>
                                <p className="text-lg">{order.info.Date?.slice(0, -3)}</p>
                            </div>
                            <div className="mr-8">
                                <p style={{color: "grey"}}>{strings.s_order_status_orderId_title}</p>
                                <p className="text-lg">{order.info.OrderID}</p>
                            </div>
                        </div>
                        <div className="pb-8">
                            <p style={{color: "grey"}}>{strings.s_order_status_table_title}</p>
                            <p className="text-lg">#{table?.TableName}</p>
                        </div>
                        <div className="pb-2">
                            <p style={{color: "grey"}}>{strings.s_order_status_items_title}</p>
                        </div>
                    </div>
                }

                {loading &&
                    <div>
                        <AiOutlineLoading3Quarters size={50} className="animate-spin m-auto" color="var(--theme-primary-color)"/>
                    </div>
                }
                {!loading &&

                    order.info.Orderitems.map(item => {
                        return <OrderStatusScreenItem key={uuid()} item={item} />
                    })
                }
                {!loading &&

                    <div className="pb-2">
                        {order.info.CustomerGeneralNote !== "-" &&
                            <div className="mb-4">
                                <p className="" style={{color: "var(--theme-primary-color)"}}>
                                    {strings.s_order_status_comments_title}:
                                </p>
                                <TextP>{order.info.CustomerGeneralNote}</TextP>
                            </div>
                        }

                        <div className="flex place-content-between items-center content-center">
                            <div>
                                <Title2 className="inline-block pr-2">
                                    {strings.s_order_status_total_title}:
                                </Title2>
                            </div>
                            <Title1>{formatCurrency(order.info.AmountTotal)}
                            </Title1>
                        </div>
                        <hr className="my-3"/>
                        <div className="flex place-content-between items-center content-center">
                            <div  style={{width: "200px"}}>
                                <Title2 className="inline-block pr-2">{strings.s_order_status_payment_title}:</Title2>
                            </div>
                            <div className="payment-logo flex justify-end items-center grow">
                                {getPaymentTypeById(paymentMethods, order.info.PaymentTypeID ?? "5")?.PaymentTypeName}
                                <img width={49} src={getPaymentIconById(order.info.PaymentTypeID ?? "5")} alt=""/>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <NotificationToast enableMultiContainer containerId={'order-status'} closeButton={CloseNotificationButton}  />
            <AppCredits />
        </div>
    )
}
