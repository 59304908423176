import { initializeApp } from "firebase/app";
import { getMessaging, getToken, Messaging, onMessage} from "firebase/messaging";
import CONFIG from "../../app/config";
import {toast} from "react-toastify";

const firebaseApp = initializeApp(CONFIG.FIREBASE_CONFIG);
let messaging: Messaging | null = null;

try{
    messaging = getMessaging(firebaseApp);
    // requestNotificationPermission();
    
    
    // console.log('Firebase Messaging loaded ')
}catch (e) {
    // console.log('Firebase Messaging could not be loaded')
}



export const getFirebaseToken = () => {
    return new Promise<string>((resolve, reject) => {
        if(!messaging){
            reject("No messaging")
        }else{
            getToken(messaging,
                {
                    vapidKey: 'BBvqe5wnQPPNnq1dFAvHmNEz8Ml0OY0DL24lnL8mVtJglq3Y8YI8we7HEMwIbejL3JpzAbSQPQrLi6Jur9ZqJuY'
                }).then(token => {
                resolve(token)
            })
                .catch(e => reject(e))
        }

    })
}
function showInPageNotification(title:string, body:string) {
    // Implement logic to show an in-page notification (e.g., a modal or toast)
    const toast = document.createElement('div');
    toast.className = 'toast-notification';
    toast.innerHTML = `<strong>${title}</strong><p>${body}</p>`;
    document.body.appendChild(toast);
  
    // Optional: Remove toast after a few seconds
    setTimeout(() => {
      toast.remove();
    }, 5000);
  }
export const firebaseMessageListener = () =>
    new Promise((resolve, reject) => {
      if (!messaging) {
        reject('No messaging');
      } else {
        // Track visibility state
        let isPageVisible = document.visibilityState === 'visible';
  
        // Handle visibility changes (for when the page is minimized or the tab is switched)
        const handleVisibilityChange = () => {
          isPageVisible = document.visibilityState === 'visible';
        };
  
        // Add event listener for visibility changes
        document.addEventListener('visibilitychange', handleVisibilityChange);
  
        // Listen for Firebase messages
        onMessage(messaging, (payload) => {
          const { notification } = payload;
          if (notification) {
            // Show notification if permission is granted
            if (Notification.permission === 'granted') {
              if (isPageVisible) {
                // Foreground notification (when the page is visible)
                showInPageNotification(notification.title+"", notification.body+"");
                console.log('Foreground Notification:', notification.body);
                // new Notification(notification.title+"", {
                //   body: notification.body,
                //   icon: '/ninax100.png',
                // });
              } else {
                // Background notification (when the page is minimized or in the background)
                console.log('Background Notification:', notification.title);
                new Notification(notification.title+"", {
                  body: notification.body,
                  icon: '/ninax100.png',
                });
              }
            }
          }
          resolve(payload);
        });
  
        // Cleanup event listener when no longer needed
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }
    });

// export const firebaseMessageListener = () =>
//     new Promise((resolve, reject) => {
//         if(!messaging){
//             reject("No messaging")
//         }else {
//             onMessage(messaging, (payload) => {
//                 const { notification } = payload;
//                 if (notification) {
//                     if (Notification.permission === "granted") {
//                         new Notification(notification.title+"", {
//                             body: notification.body,
//                             icon: "/ninax100.png" ,
//                         });
//                     }

                
//                 }
//                 resolve(payload);
//             });
//         }
//     });
