import {toast} from "react-toastify";
import React, {useEffect} from "react";
import {firebaseMessageListener} from "../config";
import {NotificationToast} from "../../../styled_components/Notifications";
import {AiOutlineClose} from "react-icons/ai";
import {useAppSelector} from "../../../app/hooks";
import {selectUser} from "../../user/userSlice";
import CONFIG from "../../../app/config";

export function FirebaseNotifications() {

    const user = useAppSelector(selectUser);

    useEffect(() => {
        if(CONFIG.ENABLE_FOREGROUND_NOTIFICATIONS_USER_IDS.includes(user.RegisteredUserID) && CONFIG.ENABLE_FOREGROUND_NOTIFICATIONS){
            firebaseMessageListener().then((payload) => {
                // @ts-ignore
               

                // setNotifications((prev) => [...prev, payload]);
                    // console.log("message: "+payload.notification.body);
                    toast(payload.notification.body, {
                        containerId: 'firebase',
                        position: "bottom-center",
                        autoClose: false,
                        hideProgressBar: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        type: "success",
                    });
            
            }).catch(err => console.log('failed: ', err));
        }
    }, [user])

    const CloseButton = ({closeToast}: any) => (
        <AiOutlineClose onClick={closeToast} color="white" />
    )

    return (
        <NotificationToast enableMultiContainer containerId={'firebase'} closeButton={CloseButton}  />
    )
}
