import {useAppDispatch} from "../../../app/hooks";
import {Navigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {EstablishmentInterface} from "../../vendor/vendorInterface";
import {getQrOutlets} from "../../vendor/vendorRepository";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {Transition} from "@headlessui/react";
import {setVendorColor} from "../../theme/themeSlice";
import {setEstablishment} from "../../vendor/establishmentSlice";
import {initSession} from "../../session/sessionSlice";
import {SessionHardReset} from "../../session/resets";
import Cookies from "universal-cookie";
import CONFIG from "../../../app/config";

interface MultiVendorStatusI {
    type: "single" | "multi" | "none"
}

export function Load() {
    const [error, setError] = useState(false);
    const [preloaded, setPreloaded ] = useState(false);
    const [loaded, setLoaded ] = useState(false);
    const [splashImage, setSplashImage] = useState("");
    const [splashColor, setSplashColor] = useState("");
    const [multiVendorStatus, setMultiVendorStatus] = useState<MultiVendorStatusI>({type: "none"})
    const dispatch = useAppDispatch();
    
    const cookies = new Cookies();
    // cookies.set('testCookie', 'testValue', { path: '/' });
    let {urlId} = useParams();
    let refId: string | undefined;

    if(cookies.get('refid')){
        refId = cookies.get('refid'); 
        // console.log("1");
    }else{
        
        // console.log("2");
        refId  = urlId;
    }
    // refId  = '33d9c4c6-dd95-435a-9765-f5d29d9aa486';
    // console.log(". "+refId);

    useEffect(() => {
        if (refId) {
            cookies.remove('refid');
            
            // cookies.set('refid', '', { path: '/', domain: '.ninatech.co' });
            // cookies.remove('refid', { path: '/', domain: '.ninatech.co' });
            // console.log(".:", refId);
            SessionHardReset(dispatch);
            preloadApp(refId)
                .then(res => {
                    dispatch(initSession());
                    if(res.Outlets.length === 1 ){
                        setMultiVendorStatus({type: "single"})
                        localStorage.setItem('load', JSON.stringify({
                            id: res.Outlets[0].OutletID,
                            table: res.TableID
                        }))
                        localStorage.setItem('tableload', JSON.stringify({
                            id: res.Outlets[0].OutletID,
                            table: res.TableID
                        }))
                        cookies.remove('refid');
                        setLoaded(true);
                    }
                    if(res.Outlets.length > 1){
                        console.log(":", refId);
                        console.log(":", res);
                        setPreloaded(true);
                        setMultiVendorStatus({type: "multi"})
                        setSplashImage(res.CoverImage);
                        setSplashColor(res.Color);
                        dispatch(setVendorColor('E84D39'));
                        dispatch(setEstablishment(res));
                        // setVendorColor('E84D39');
                        setEstablishment(res);
                        setTimeout(() => {
                            // cookies.remove('refid');
                            cookies.set('refid', '', { path: '/', domain: '.ninatech.co' });
                            cookies.remove('refid', { path: '/', domain: '.ninatech.co' });
                            setLoaded(true);
                        }, getDurationRemaining(performance.now() - 1))
                    }
                })
                .catch(() => setError(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const preloadApp = (id: string) => {
        console.log("preloadApp: "+id);
        return new Promise<EstablishmentInterface>((resolve, reject) => {
            getQrOutlets(id).then(outlets => {
                resolve(outlets)
            }).catch(e => reject(e));
        })
    }

    return (
        <div>
            {!preloaded && !error &&
                <div className="flex items-center justify-center h-screen">
                    <AiOutlineLoading3Quarters size={50} className="animate-spin" color="var(--theme-primary-color)"/>
                </div>
            }
            <Transition
                show={preloaded && !loaded && !error}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="w-screen h-screen flex justify-center items-center"
                     style={{background: splashColor ? "#"+splashColor :"var(--theme-primary-color)"}}>
                    {splashImage !== "" ?
                        <img className={"splash-logo"} src={CONFIG.COVERS_URL + splashImage} alt=""/>
                    :
                        <img className={"splash-logo"} src="/images/demo/splash_logo.png" alt=""/>
                    }
                </div>
            </Transition>


            {error &&
                <div className="flex items-center justify-center h-screen flex-col">
                    <AiOutlineLoading3Quarters size={50} className="animate-spin" color="var(--theme-primary-color)"/>
                    <br/>
                    <div>error loading...</div>
                </div>
            }
            {loaded && !error && multiVendorStatus.type === "single" && <Navigate replace to={"/splash"}/>}
            {loaded && !error && multiVendorStatus.type === "multi" && <Navigate replace to={"/outlets"}/>}
        </div>
    );
}

const getDurationRemaining = (elapsed: number) => {
    const loadDelay = 1;
    if(elapsed >= loadDelay) {
        return 1;
    }else{
        return loadDelay - elapsed;
    }
}
